<template>
    <div class="w-full">
        <div :class="[{'py-4': !type, 'py-8': type}]" v-if="!titleOff">
            <div class="text-[#292B33]"
                 :class="[{'px-4 text-xl text-center ': type === 'popup'}, {'text-base md:text-xl text-left': type === 'inline'}]">
                {{ title }}
            </div>

            <div :class="[{'text-center x-4':type==='popup'}]" class="py-2 text-base" v-if="subtitle.length">{{ subtitle }}</div>
        </div>

        <div class="grid grid-cols-2 gap-2">
            <a
                    href="https://t.me/fominclinic_kids_bot"
                    target="_blank"
                    class="flex items-center mb-4 border rounded-full border-[#EFEDF2] px-3 py-1 text-lg text-[#2D2A34] hover:text-black h-12"
            >
                <span class="grow text-center">Telegram</span>

                <UIIconTg />
            </a>
            <a
                    :href="link"
                    target="_blank"
                    class="flex items-center mb-4 border rounded-full border-[#EFEDF2] px-3 py-1 text-lg text-[#2D2A34] hover:text-black"
            >
                <span class="grow text-center">WhatsApp</span>

                <UIIconWa />

            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'subtitle', 'link', 'type', 'titleOff'],
    computed: {
        title() {
            return this.title ? this.title : 'Для записи напишите нам в любой мессенджер:';
        },
        subtitle() {
            return this.subtitle ? this.subtitle : '';
        },
        link() {
            return this.link ? this.link : 'https://wa.me/79161006469';
        },
    }
};
</script>
