<template>
    <Dialog :open="isOpen" @close="hidePopup" class="relative z-50">
        <div class="fixed inset-0 bg-red-100 opacity-75" aria-hidden="true"/>
        <div class="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel class="w-full max-w-sm bg-white relative p-4 rounded-2xl">
                <div class="absolute right-4">
                    <div class="rounded-3xl p-1 flex gap-1 items-start  bg-[#f8f7fa] cursor-pointer"
                         @click="hidePopup">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.75735 7.75735L16.2426 16.2426" stroke="#9E9BA3"
                                  stroke-width="1.5"
                                  stroke-linecap="round"></path>
                            <path d="M16.2426 7.75735L7.75736 16.2426" stroke="#9E9BA3"
                                  stroke-width="1.5"
                                  stroke-linecap="round"></path>
                        </svg>
                    </div>
                </div>
                <div class="text-center pt-6">
                    <svg class="inline-block" width="54" height="52" viewBox="0 0 54 52" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_345_2752)">
                            <path d="M27.7431 18.1082C28.1147 12.7247 25.3899 7.70824 20.6835 5.01647C16.2247 2.44706 9.90823 2.20236 5.69723 5.38353C1.61007 8.56471 -0.619288 14.1929 0.123831 19.2094C1.11466 26.0612 6.06878 30.9553 10.4036 35.8494C15.6055 41.6 20.8073 47.4729 27.6192 51.5106C30.9633 53.5906 34.0596 48.3294 30.7156 46.2494C24.8945 42.7012 20.3119 37.6847 15.8532 32.6682C11.8899 28.3859 5.57337 23.0024 6.19264 16.5177C6.5642 12.1129 9.90823 8.68706 14.4908 9.17647C19.0734 9.78824 21.922 13.5812 21.5504 18.1082C21.3027 22.0235 27.4954 22.0235 27.7431 18.1082Z"
                                  fill="#A2E7E0"/>
                            <path d="M27.7431 18.1082C27.6193 18.72 27.6193 18.5976 27.7431 18.5976C27.9908 18.2306 28.1147 17.8635 28.3624 17.3741C28.8578 16.3953 29.4771 15.2941 29.9725 14.3153C30.8394 12.6023 31.7064 10.7671 32.945 9.29882C36.0413 5.38352 42.8532 4.77176 46.445 8.68705C49.1697 11.7459 47.6835 17.1294 46.5688 20.5553C44.9587 25.0823 42.4816 28.7529 39.5092 32.5459C35.6697 37.44 31.7064 42.5788 27 46.7388C24.0275 49.3082 28.3624 53.7129 31.3349 51.0212C36.4128 46.4941 40.7477 40.8659 44.9587 35.4823C48.6743 30.8329 51.5229 25.9388 53.133 20.1882C54.6193 14.8047 54.867 7.95294 50.2844 3.91529C44.711 -1.10118 35.6697 -1.34589 29.9725 3.54823C27.7431 5.50588 26.3807 8.07529 25.0183 10.6447C23.7798 12.9694 22.0459 15.5388 21.6743 18.1082C21.4266 19.6988 23.1605 21.1671 24.6468 21.1671C26.5046 21.1671 27.6193 19.6988 27.7431 18.1082Z"
                                  fill="#A2E7E0"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_345_2752">
                                <rect width="54" height="52" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <div class="max-w-xl">
                    <UISocialButtons :title="title" :subtitle="subtitle" :link="link" type="popup"/>
                </div>
            </DialogPanel>
        </div>
    </Dialog>
</template>

<script>
import {
    Dialog,
    DialogPanel,
} from '@headlessui/vue'


export default {
    props: ['isOpen', 'messTitle', 'messSubtitle', 'messLink'],
    setup() {
        return {}
    },
    methods: {
        hidePopup() {
            this.$emit('setIsOpen', false);
        }
    },
    computed: {
        title() {
            return this.messTitle ? this.messTitle : 'Для записи напишите нам в любой мессенджер';
        },
        subtitle() {
            return this.messSubtitle ? this.messSubtitle : '';
        },
        link() {
            return this.messLink ? this.messLink : 'https://wa.me/+79161006469';
        },
    },
    components: {
        Dialog,
        DialogPanel,
    }
}
</script>